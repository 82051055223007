@mixin invalid {
  &.invalid{
    border: 1px solid red;
    color: red;

    &+label{
      display: block;
      margin: -18px 0 -18px 10px;
      color: red;
      font-size: 12px;
    }
  }
}

.cart_modal{
    position: fixed;
    display: flex;
    z-index: 1000;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    height: 100vh;
    padding: 60px 0;
    background: rgba(35, 31, 32, 0.80);
    transition: opacity 0.3s;
    overflow-y: auto;
    &.active{
      opacity: 0;
      pointer-events: none;
    }
    .btn_modal{
      position: fixed;
      top: 10px;
      right: 20px;
      background: rgba(35, 31, 32, 0);
      z-index: 100;
      img{
        width: 35px;
        height: 35px;
      }
    }
    @media screen and (max-width: 768px) {
      .btn_modal{
        position: fixed;
        top: 10px;
        right: 20px;
        background: rgba(35, 31, 32, 0.2);
      }
    }
    .wrapper{
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 560px;
      padding: 40px;
      margin: 0 auto;
      background: #ffffff;
      border-radius: 16px;
      gap: 24px;
      margin-bottom: 40px;
      
      hr{
        border-bottom: 1px solid rgba(35, 31, 32, 0.8);
      }
      h1{
        font-size: 18px;
        font-weight: 700;
        display: flex;
        flex-direction: column;

        >div{
          display: inline-flex;
          span{
            opacity: 0.7;
          }
          span[class="active"]{
            opacity: 1;
            color: #ff7f41;
          }
        }
      }
      
      .orderSent {
        display: flex;
        flex-direction: column;
        gap: 24px;
        z-index:10;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        border-radius: 16px;
        font-size:20px;
        font-weight: 500;
        h1{
          font-size: 24px;
          font-weight: 600;
        }
        ul{
          display: flex;
          flex-direction: column;
          gap: 12px;
          font-size: 14px;
        }
      }
      .orderSent ~ * {
        display: none;
      }
      form{
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        gap: 24px;
        button{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          background: #ff7f41;
          border: 1px solid #ff7f41;
          border-radius: 30px;
          color: #231f20;
          font-size: 16px;
          font-weight: 700;
          transition: background-color 0.2s ease-in-out, color 0.2s;

          span{
            margin: 10px;
          }

          &:disabled {
          height: 60px;
          padding: 0 20px;
            background-color: #ffffff !important;
            background-color: #ffffff !important;
          border: 1px solid #231f20;
            border-color: #231f20 !important;
            border-color: #231f20 !important;
          border-radius: 10px;
            opacity: 0.5
          }
        }

        label{
          display: none;
        }
        p{
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          a{
            color:#ff7f41;
          }
        }
        input{
          height: 60px;
          padding: 0 20px;
          border: 1px solid #231f20;
          border-radius: 10px;

          @include invalid;
        }
        >div{
          border: 1px solid #231f20;
          @include invalid;
          color: #231f20;
          border-radius: 10px;
        }
      }
    }
  }

  .cart_modal2 {
    position: fixed;
    display: flex;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 60px 0;
    top: 0;
    left: 0;
    background: rgba(35, 31, 32, 0.80);
    transition: opacity 0.3s;
    overflow-y: overlay;

    .wrapper2 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 760px;
        padding: 60px;
        margin: 0 auto;
        background: #ffffff;

        h1 {
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            line-height: 1.55;
            max-width: 600px;
            margin: 0 auto;
        }

        h2 {
            font-size: 24px;
            font-weight: 400;
            text-align: center;
            line-height: 1.55;
            max-width: 600px;
            margin: 0 auto;
        }

        img{
          width: 300px;
          height: 300px;
        }

        a{
          text-decoration: underline;
          color: #ffc845;
        }
    }

    .btn_modal2 {
        position: fixed;
        top: 10px;
        right: 20px;
        background: rgba(35, 31, 32, 0);
        z-index: 100;

        img {
            width: 35px;
            height: 35px;
        }
    }
}
  
