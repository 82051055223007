.SearchInput {
  position: relative;
  display: flex;
  align-items: center;
  height: 45px;
  max-width: 40%;
  min-width: 102px;
  padding-left: 20px;
  background: #fff;
  border: 1px solid #777;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 400;
  gap: 12px;

  input {
    width: 80%;

  }

  .clearBtn {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    max-width: inherit;
    width: 100%;

    .clearBtn {
     margin-right: 10px;
    }
  }

}
